import React from 'react'
import { Default } from './default'

export enum LayoutType {
  LANDING = 'landing',
  EXTERNAL = 'external',
  INTERNAL = 'internal',
  OVERLAY = 'overlay',
}

export default ({ location, children, pageContext }) => {
  const type: LayoutType = pageContext.layout

  return (
    <Default location={location} type={type}>
      {children}
    </Default>
  )
}
