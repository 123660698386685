import { styledMargin } from '../layouts/base'
import { Link } from 'gatsby'
import React from 'react'
import styled from '@emotion/styled'
import color from 'color'
import theme from '../theme/theme'

const Footer = styled.footer({
  padding: '15px 0',
  height: '50px',
  color: theme.dark,
  fontSize: '0.8rem',
  display: 'flex',
  justifyContent: 'space-between',
  textTransform: 'uppercase',
  borderTop: `1px solid ${color(theme.dark).lighten(2)}`,
})

export const FooterExternal = () => (
  <Footer css={styledMargin}>
    <span>
      <Link to="/impressum">Impressum</Link> |{' '}
      <Link to="/datenschutz">Datenschutz</Link>
    </span>
    <span>© {new Date().getFullYear()} Merle Ehm </span>
  </Footer>
)
