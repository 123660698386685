import React from 'react'
import posed, { PoseGroup } from 'react-pose'
import { css } from '@emotion/core'
import { ReactNodeArray } from 'prop-types'
import { WindowLocation } from '@reach/router'
import { runningInBrowser } from '../utils'

const timeout = 250

const routesContainer = css({
  flex: 1,
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'stretch',
})

const RoutesContainer = posed.div({
  enter: { delay: timeout, delayChildren: timeout },
  exit: { delay: timeout, delayChildren: timeout },
})

interface Props {
  children: ReactNodeArray
  location: WindowLocation
}

class Transition extends React.PureComponent<Props> {
  render() {
    const { children, location } = this.props
    // To enable page transitions on mount / initial load,
    // use the prop `animateOnMount={true}` on `PoseGroup`.
    return (
      <PoseGroup>
        <RoutesContainer
          css={routesContainer}
          key={location.pathname + location.hash + location.search}
        >
          {children}
        </RoutesContainer>
      </PoseGroup>
    )
  }
}

export const PageTransition = posed.div({
  enter: {
    opacity: 1,

    transition: { duration: 700 },
  },
  exit: {
    opacity: 0,

    transition: { duration: 700 },
  },
})

export const LandingTransition = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: { duration: 700 },
  },
  exit: {
    y: runningInBrowser ? window.innerHeight : 0,
    opacity: 0,
    transition: { duration: 700 },
  },
})

export const SpeiseTransition = posed.div({
  enter: {
    x: 0,
    //opacity: 1,
    transition: { duration: 700 },
  },
  exit: {
    x: runningInBrowser ? -window.outerWidth : 0,
    //opacity: 0,
    transition: { duration: 900 },
  },
})

export const ListTransition = posed.div({
  enter: {
    x: 0,
    transition: { duration: 700 },
  },
  exit: {
    x: runningInBrowser ? -window.innerWidth : 0,

    transition: { duration: 0, delay: 700 },
  },
})

export const ItemTransition = posed.li({
  enter: {
    opacity: 1,
    delay: ({ i }) => i * 150,
  },
  exit: {
    opacity: 0,
    delay: ({ i }) => i * 150,
  },
})

export default Transition
