import color from 'color'

const primary = 'rgb(220,0,0)'

const theme = {
  dark: 'rgba(79, 78, 79)',
  medium: 'rgb(90,88,90)',
  light: 'rgb(255,255,255)',
  shadow: 'rgba(0,0,0,0.3)',
  primary,
  backgroundPlaid: {
    backgroundColor: 'white',
    backgroundImage:
      `linear-gradient(90deg, ${color(primary)
        .darken(0.2)
        .alpha(0.5)} 50%, transparent 50%),\n` +
      `linear-gradient(${color(primary)
        .darken(0.2)
        .alpha(0.5)} 50%, transparent 50%)`,
    backgroundSize: '30px 30px',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center center',
  },
  mqLayout: '769px',
}

export default theme
