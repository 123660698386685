import { styledMargin } from '../layouts/base'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'
import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import color from 'color'
import theme from '../theme/theme'

const Menu = styled.ul({
  listStyle: 'none',
  margin: 0,
  padding: '15px 0',
  borderBottom: `1px solid ${color(theme.dark).lighten(2)}`,
  color: theme.dark,
})

const Item = styled.li({
  textTransform: 'uppercase',
  fontSize: '0.8rem',
})

const styledIcon = css({
  marginRight: '15px',
})

export const MenuExternnal = () => (
  <Menu css={styledMargin}>
    <Item>
      <FontAwesomeIcon icon={faChevronLeft} css={styledIcon} />
      <Link to="/">Zurück</Link>
    </Item>
  </Menu>
)
