import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import theme from '../theme/theme'
import typography from '../theme/typography'
import { TypographyStyle, GoogleFont } from 'react-typography'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Site = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'stretch',
  minHeight: '100vh',
  flexDirection: 'column',
  backgroundColor: theme.light,
  [`@media (min-width: ${theme.mqLayout})`]: {
    width: '60%',
    boxShadow: `5px 0 5px ${theme.shadow}`,
  },
})

export const Base = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Helmet
          defaultTitle={data.site.siteMetadata.title}
          titleTemplate={`%s | ${data.site.siteMetadata.title}`}
        >
          <html lang="de" />
          <meta name="robots" content="noindex" />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <TypographyStyle typography={typography} />
        <GoogleFont typography={typography} />

        <Site>{children}</Site>
      </React.Fragment>
    )}
  />
)

export const styledMargin = css({
  margin: '0 3vw',
})

export const styledPadding = css({
  padding: '3.2vw 3vw',
})

export const styledPage = [styledPadding, css({ backgroundColor: theme.light })]
