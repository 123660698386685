/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
//exports.shouldUpdateScroll = () => true

//exports.onRouteUpdate = () => window.scrollTo(0, 0)

const transitionDelay = 250

exports.shouldUpdateScroll = ({ pathname }) => {
  // // We use a temporary hack here, see #7758
  /*if (window.__navigatingToLink) {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = JSON.parse(
      window.sessionStorage.getItem(`@@scroll|${pathname}`)
    )
    window.setTimeout(() => window.scrollTo(...savedPosition), transitionDelay)
  }*/
  window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  return false
}
