import * as config from '../package.json'
import slugify from 'slugify'
import { Action, ActionType } from './speise'

export const guid = () => {
  return new Date().getTime()
}

export const runningInBrowser: boolean = typeof window !== 'undefined'

export const convertBlobToBase64 = (blob: Blob): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader: FileReader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(String(reader.result))
    }
    reader.readAsDataURL(blob)
  })

export const downloadImage = (imageUrl: string): Promise<string> =>
  fetch(`${config.merlehm.corsUrl}/?url=${imageUrl}`, {
    credentials: 'same-origin',
  })
    .then(response => response.blob())
    .then(convertBlobToBase64)

export const pushFilesToGitlab = (commitMessage: string, actions: any[]) => {
  return window.fetch(
    'https://gitlab.com/api/v4/projects/10223592/repository/commits',
    {
      method: 'POST',
      body: JSON.stringify({
        actions,
        branch: 'master',
        commit_message: commitMessage,
      }),
      credentials: 'same-origin',
      headers: {
        'PRIVATE-TOKEN': '53PgV-zSp8sRU-Jqp_Jx',
        'Content-Type': 'application/json',
      },
    }
  )
}

interface GitlabAction {
  action: ActionType
  file_path: string
  encoding: string
  content: string
}

export const createGitlabAction = (
  action: ActionType,
  filePath: string,
  content: string,
  encoding: string = 'text'
) =>
  ({
    encoding,
    content,
    action,
    file_path: filePath,
  } as GitlabAction)

export const getUniqueFilename = (filename: string) =>
  `${slugify(filename, { lower: true })}-${guid()}`

export const getCurrentDate = () => {
  const date: Date = new Date()
  const year: number = date.getFullYear()
  const day = date.getDate()
  const month = date.getMonth()
  const addZero = (num: number) => (num < 10 ? `0${num}` : num)

  return `${year}-${addZero(month)}-${addZero(day)}`
}

export const getAgeInDays = (dateInput: string) => {
  const [year, month, day] = dateInput.split('-')
  const date = new Date(Number(year), Number(month), Number(day))
  const timeDiff = Math.abs(Date.now() - date.getTime())

  return Math.ceil(timeDiff / (1000 * 3600 * 24))
}

export const extractImageData = (dataURL: string) => {
  const base64Key: string = ';base64,'
  const indexOfBase64Key = dataURL.indexOf(base64Key)

  return {
    extension: dataURL.substring('data:image/'.length, indexOfBase64Key),
    data: dataURL.substring(indexOfBase64Key + base64Key.length),
  }
}

export const loadActions = (): Action[] => {
  if (runningInBrowser) {
    const actions: string = localStorage.getItem('actions')
    if (actions) {
      return JSON.parse(actions)
    }
  }
  return []
}

export const addAction = (action: Action): void => {
  const actions: Action[] = loadActions()
  actions.push(action)
  localStorage.setItem('actions', JSON.stringify(actions))
}

export const removeAction = (index: number): void => {
  const actions: Action[] = loadActions()
  actions.splice(index, 1)
  localStorage.setItem('actions', JSON.stringify(actions))
}

export const extractBaseURL = (url: string) => {
  const pathArray = url.split('/')
  const protocol = pathArray[0]
  const host = pathArray[2]
  return `${protocol}//${host}`
}

export const getQueryVariable = (query: string, variable: string) => {
  const vars = query.split('&')

  for (const value of vars) {
    const pair = value.split('=')
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1])
    }
  }
}
