import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarAlt,
  faExchangeAlt,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'
import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import color from 'color'
import theme from '../theme/theme'

const styledIcon = css({
  color: `${color(theme.dark).lighten(1.2)}`,
  fontSize: '1.7rem',
})

const styledActiveIcon = css({
  color: theme.light,
  fontSize: '1.7rem',
})

const Menu = styled.ul({
  margin: 0,
  listStyle: 'none',
  flexDirection: 'row',
  display: 'flex',
})

const MenuItem = styled.li({
  marginLeft: '12px',
  padding: '3px',
  width: '38px',
  height: '38px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
})
const MenuItemActive = styled.li({
  marginLeft: '12px',
  backgroundColor: theme.primary,
  padding: '3px',
  width: '38px',
  height: '38px',
  borderRadius: '2px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
})
const Nav = styled.div({
  borderBottom: `1px solid ${color(theme.dark).lighten(2)}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '10px',
  marginBottom: '20px',
})

const Search = styled.div({
  backgroundColor: theme.light,
  padding: '15px 3vw',
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 100,
  height: '129px',
  boxShadow: `0 3px 3px ${theme.shadow}`,
  [`@media (min-width: ${theme.mqLayout})`]: {
    width: '60%',
  },
})

const NavTitle = styled.h1({
  margin: 0,
  lineHeight: '1.2rem',
  textTransform: 'uppercase',
  fontSize: '1.2rem',
  'font-weight': '600',
})

export const MenuInternal = () => (
  <Search>
    <Nav>
      <NavTitle>Speiseliste</NavTitle>
      <Menu>
        <MenuItemActive>
          <Link to="/speiseliste">
            <FontAwesomeIcon icon={faUtensils} css={styledActiveIcon} />
          </Link>
        </MenuItemActive>
        <MenuItem>
          <Link to="/speiseplan">
            <FontAwesomeIcon icon={faCalendarAlt} css={styledIcon} />{' '}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/aktionsliste">
            <FontAwesomeIcon icon={faExchangeAlt} css={styledIcon} />{' '}
          </Link>
        </MenuItem>
      </Menu>
    </Nav>
  </Search>
)
