import React from 'react'
import Transition from '../components/transition'
import styled from '@emotion/styled'
import theme from '../theme/theme'
import { Base } from './base'
import { LayoutType } from './index'
import { FooterExternal } from '../components/footer-external'
import { MenuExternnal } from '../components/menu-external'
import { MenuInternal } from '../components/menu-internal'

const Main = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  overflowX: 'hidden',
})

const Header = styled.div({
  height: '122px',
  backgroundColor: 'white',
  ...theme.backgroundPlaid,
  backgroundPosition: 'bottom center',
  boxShadow: `inset 0 -5px 5px ${theme.shadow}`,
  [`@media (min-width: ${theme.mqLayout})`]: {
    display: 'none',
  },
})

export const Default = ({ children, location, type }) => {
  console.log(type)
  return (
    <Base>
      {type === LayoutType.EXTERNAL || type === LayoutType.LANDING ? (
        <Header />
      ) : null}
      {type === LayoutType.EXTERNAL ? <MenuExternnal /> : null}
      {!type || type === LayoutType.INTERNAL ? <MenuInternal /> : null}
      <Main>
        {location ? (
          <Transition location={location}>{children}</Transition>
        ) : (
          { children }
        )}
      </Main>
      {type === LayoutType.LANDING || type === LayoutType.EXTERNAL ? (
        <FooterExternal />
      ) : null}
    </Base>
  )
}
