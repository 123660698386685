import Typography from 'typography'
import theme from './theme'
const md = '769px'
const typography = new Typography({
  googleFonts: [
    {
      name: 'Asap',
      styles: ['400', '500', '600', '700'],
    },
    {
      name: 'Open Sans',
      styles: ['400', '500', '600', '700'],
    },
  ],
  baseFontSize: '16px',
  baseLineHeight: 1.5,
  headerFontFamily: ['Asap', 'sans-serif'],
  bodyFontFamily: ['Asap', 'sans-serif'],
  bodyColor: theme.dark,
  overrideStyles: () => ({
    [`@media (min-width: ${md})`]: {
      'html, body': {
        ...theme.backgroundPlaid,
      },
    },
    h1: {
      fontSize: '1.8rem',
      fontWeight: '500',
      color: theme.dark,
      lineHeight: '2.3rem',
      letterSpacing: '-0.05rem',
    },
    'h2,h3,h4,h5': {
      color: theme.medium,
      fontSize: '1.35rem',
      marginBottom: '0.55rem',
      fontWeight: '500',
      letterSpacing: '-0.05rem',
    },
    'h3,h4,h5': {
      fontWeight: 'normal',
    },
    'h4,h5': {
      fontWeight: 'normal',
    },
    '.icon-spacing': {
      marginRight: '16px',
    },
    a: {
      color: theme.primary,
      cursor: 'pointer',
      wordBreak: 'break-all',
    },
    'a:active': {
      opacity: 0.8,
    },
    strong: {
      color: theme.medium,
      fontWeight: 'bold',
    },
    li: {
      margin: 0,
    },
    '*, *:focus, *:active': {
      webkitTapHighlightColor: 'transparent',
      webkitUserSelect: 'none',
      webkitTouchCallout: 'none',
      outline: 'none',
      cursor: 'default',
    },
    form: {
      margin: 0,
    },
  }),
  // See below for the full list of options.
})

export default typography
