// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/builds/linasale/merlehm-de/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-speise-js": () => import("/builds/linasale/merlehm-de/src/templates/speise.js" /* webpackChunkName: "component---src-templates-speise-js" */),
  "component---src-pages-404-js": () => import("/builds/linasale/merlehm-de/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktionsliste-tsx": () => import("/builds/linasale/merlehm-de/src/pages/aktionsliste.tsx" /* webpackChunkName: "component---src-pages-aktionsliste-tsx" */),
  "component---src-pages-datenschutz-js": () => import("/builds/linasale/merlehm-de/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-import-tsx": () => import("/builds/linasale/merlehm-de/src/pages/import.tsx" /* webpackChunkName: "component---src-pages-import-tsx" */),
  "component---src-pages-impressum-js": () => import("/builds/linasale/merlehm-de/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/builds/linasale/merlehm-de/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-speiseliste-js": () => import("/builds/linasale/merlehm-de/src/pages/speiseliste.js" /* webpackChunkName: "component---src-pages-speiseliste-js" */),
  "component---src-pages-speiseplan-tsx": () => import("/builds/linasale/merlehm-de/src/pages/speiseplan.tsx" /* webpackChunkName: "component---src-pages-speiseplan-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/linasale/merlehm-de/.cache/data.json")

